<template>
  <div :class="$style.home">
    <div id="left" :class="$style.box">
      <div :class="$style.left">
        <div :class="$style.title">内容正文</div>
        <BjEditor ref="editor" v-model="add.introduce" placeholder="这里是正文" />
      </div>

      <div :class="$style.right">
        <div :class="$style.title">内容配置</div>
        <div :class="$style.mybox">
          <div :class="$style.label">
            <span class="required">*</span>
            内容封面
          </div>
          <div :class="$style.value">
            <selectMaterial :data="add.cover" type="single" :allow="['image']" @change="coverChange" />
            <div class="tip mt-10">建议尺寸900px*500px；大小不超过2M；格式支持JPG、PNG。</div>
          </div>
        </div>

        <div :class="$style.mybox">
          <div :class="$style.label">内容作者</div>
          <div :class="$style.value">
            <BjInput v-model="add.name" placeholder="请输入内容作者" />
          </div>
        </div>

        <div :class="$style.mybox">
          <div :class="$style.label">阅读原文</div>
          <div :class="$style.value">
            <BjInput v-model="add.name1" placeholder="请设置阅读原文的链接" />
            <div class="tip mt-10">点击“阅读原文”可以跳转的链接。</div>
          </div>
        </div>

        <div :class="$style.mybox">
          <div :class="$style.label">内容摘要</div>
          <div :class="$style.value">
            <a-textarea
              v-model="add.description"
              :auto-size="{ minRows: 4, maxRows: 4 }"
              placeholder="内容消息的摘要描述，如不填写，则默认抓取正文前64个字。"
              :max-length="500"
            />
          </div>
        </div>

        <div :class="$style.mybox">
          <div :class="$style.label">封面显示</div>
          <div :class="$style.value">
            <BjSwitch v-model="add.comment_status" size="default" />
            <div class="tip mt-10">是否将封面图片显示在正文中。</div>
          </div>
        </div>

        <div :class="$style.mybox">
          <div :class="$style.label">允许评论</div>
          <div :class="$style.value">
            <BjSwitch v-model="add.comment_status1" size="default" />
          </div>
        </div>
      </div>
    </div>

    <div :class="$style.footer" :style="{ width: footWidth }">
      <BjButton class="mr-10" type="primary" @click="onSave()">
        <i class="ri-save-line left" />
        立即保存
      </BjButton>
      <BjButton @click="onCannel()">取消添加</BjButton>
    </div>
  </div>
</template>

<script>
import BjEditor from '@/components/BjEditor'
import selectMaterial from '@/components/selectMaterial'

export default {
  name: 'Home',
  components: {
    selectMaterial,
    BjEditor,
  },
  data() {
    return {
      footWidth: '800px',
      add: {
        introduce: null,
        cover: [],
        name: null,
        name1: null,
        description: null,
        comment_status: 1,
        comment_status1: 1,
      },
    }
  },
  mounted() {
    this.footWidth = document.getElementById('left').offsetWidth + 'px'
    window.onresize = () => {
      this.footWidth = document.getElementById('left').offsetWidth + 'px'
    }
  },
  methods: {
    coverChange(data) {
      this.add.cover = data
    },
    onSave() {
      this.$message.error('微信服务器连接失败，请稍后再试')
    },
    onCannel() {
      this.$router.push({
        name: 'spreadDistribute',
      })
    },
  },
}
</script>

<style lang="less" module>
.home {
  .box {
    display: flex;

    .left {
      flex: 1;
      background: #fff;
      padding: 20px;
      margin-right: 20px;
    }

    .right {
      width: 360px;
      background: #fff;
      padding: 20px;
    }
  }

  .title {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .mybox {
    display: flex;
    margin-bottom: 20px;

    .label {
      height: 40px;
      line-height: 40px;
      width: 100px;
    }

    .value {
      flex: 1;

      :global {
        .ant-form-item {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

.footer {
  position: fixed;
  bottom: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  width: 880px;
  height: 60px;
  padding: 0 20px;
  line-height: 60px;
  background: #fff;
  box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.05);
}
</style>
